import React from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { ExitToApp } from '@mui/icons-material';
import Toolbar from '@mui/material/Toolbar';

const NavBar = () => {

    return (
        <AppBar position="static" >
            <Toolbar>
                <IconButton
                    onClick={() => { }}
                    edge="start"
                    color="inherit"
                    aria-label="exit"
                >
                    <ExitToApp />
                </IconButton>
                
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item xs={3}>
                        <Link color="inherit" href={"/"}>
                            Content Try
                        </Link>
                    </Grid>
                </Grid>

            </Toolbar>
        </AppBar>
    );
};

export default NavBar;