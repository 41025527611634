import { Button, Grid, TextField, FormControlLabel, FormGroup, Switch } from '@mui/material';
import React, { useState } from 'react';
import { contentTryPostRequest, IContentTryPostRequest } from '../../api/api_requests';


export interface IContentTryProps {
    email: string;
    zipFile: File | null,
    enhance: boolean,
    imageFile: File | null,
}

export default function ContentTryScreen() {
    const [disable, setDisable] = useState(false);
    const [formData, setFormData] = useState<IContentTryProps>({ email: '', imageFile: null, zipFile: null, enhance: false });

    const onTextFieldChange = (val: string) => {
        setFormData({ ...formData, email: val });
    }

    const onClickButton = () => {
        if (formData.email !== "" && formData.zipFile && formData.imageFile) {
            console.log('Form data: ', formData);
            let data: IContentTryPostRequest = {
                email: formData.email,
                zipFile: formData.zipFile,
                enhance: formData.enhance,
                imageFile: formData.imageFile
            }
            setDisable(true);
            contentTryPostRequest(data).finally(() => setDisable(false));
        } else {
            alert("Please fill all the fields");
        }
    }

    return (
        <div>
            <h1>Content Try Screen</h1>

            <div style={{ marginLeft: 80, marginRight: 120, textAlign: "left" }}>
                <h2 >Yüklediğin zip dosyasının içerisinde olması gerekenler: </h2>
                <ul>
                    <h2>
                        <li> .mp4 formatında bir video</li>
                        <li> .png formatında, videonun içerisinde swaplamak istediğin yüzlerin screenshotları </li>
                    </h2>
                </ul>
                <h2>Bunlara ek olarak hiçbir dosya isminde boşluk bulunmamalı.</h2>
                <h2 >Her saatte content deneme isteği atabilirsin, ancak sonuçları her gün 15.00-17.00 arasında gönderiyor olacağız. Mailin bu süre içerisinde gelmezse spam folderını mutlaka kontrol et.</h2>
            </div>

            <Grid item height={120} />

            <TextField style={{ width: 250 }}
                onChange={(props) => onTextFieldChange(props.target.value)}
                variant='outlined'
                label="email"
                type="email"
            />

            <Grid item margin={2} padding={2}>

                <Button
                    variant="contained"
                    component="label"
                    color={formData.imageFile ? 'success' : 'primary'}
                    style={{ margin: 25 }}
                >
                    Upload A Selfie (.jpg)
                    <input
                        type="file"
                        accept='image/jpeg'
                        hidden
                        onChange={(e) => {
                            try {
                                console.log(e.target.files![0]);
                                setFormData({ ...formData, imageFile: e.target.files![0] });
                            } catch (error) {
                                console.log(error);
                            }
                        }}
                    />
                </Button>


                <Button
                    variant="contained"
                    component="label"
                    color={formData.zipFile ? 'success' : 'primary'}
                    style={{ margin: 25 }}
                >
                    Upload Zip File
                    <input
                        type="file"
                        accept='application/zip'
                        hidden
                        onChange={(e) => {
                            try {
                                console.log(e.target.files![0]);
                                setFormData({ ...formData, zipFile: e.target.files![0] });
                            } catch (error) {
                                console.log(error);
                            }
                        }}
                    />
                </Button>
            </Grid>

            <div style={{ height: 20 }} />

            <FormControlLabel
                label="Enhance"
                control={
                    <Switch
                        value={formData.enhance}
                        onChange={(val) => {
                            setFormData({ ...formData, enhance: val.currentTarget.checked })
                        }} />}
            />

            <div style={{ height: 120 }} />


            <Button disabled={disable} onClick={onClickButton} variant="contained">
                Test Content Video
            </Button>

        </div >
    )
}