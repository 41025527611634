import axios, { AxiosError } from 'axios';
import { IContentTryProps } from '../screens/content_try'

const contentTryPostUrl = "https://content-panel-server.cwfaceswap.com/content/tryVideo";

export interface IContentTryPostRequest {
    email: string;
    zipFile: File;
    enhance: boolean;
    imageFile: File;
}
const contentTryPostRequest = async (data: IContentTryProps) => {
    try {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const formData = new FormData();
        formData.append('zipFile', data.zipFile!, 'video.zip');
        formData.append('imageFile', data.imageFile!, 'image.jpeg');
        formData.append('email', data.email)
        formData.append('enhance', String(data.enhance))
        const res = await axios.post(contentTryPostUrl, formData, config);

        alert("Succesfully sent!");
    } catch (e: any) {
        alert("Error Occured!");
        console.log(e);

    }
}

export { contentTryPostRequest, }