import React from "react";
import {
    BrowserRouter as Router, Route, Switch
} from "react-router-dom";
import NavBar from "./components/NavBar";
import ContentTryScreen from "./screens/content_try";


export default function AppNav() {

    return (
        <div>
            <Router>
                <>
                    <NavBar />
                    <Switch>
                        <Route exact path={"/"}>
                            <ContentTryScreen />
                        </Route>
                        <Route component={() => <h2>Error 404, Page Not Found!</h2>} />
                    </Switch>
                </>
            </Router>
        </div>
    )

}